<template>
    <div class="contenter" style="height:100%;padding-top:88px;padding-bottom:54px;">
        <Header style="margin-top:-88px;position:relative;"></Header>
        <div style="min-height:100%;width:100%;backgroup:#fdfdfd;">
            <div class="service_Box">
                <div class="wrapperMar" style="width:1146px;">
                    <div class="CommontTitle">
                        <div class="comTitleName titmart"><span class="comName">数据定制</span></div>
                    </div>
                    <div class="servicebox">
                        <p class="pea">为进一步的服务于教学与科研，不断提升服务品质，EPS DATA向广大用户提供数据定制服务，满足用户进行课题研究和论文写作过程中对数据的需求。有数据定制需求的用户请按要求填写相关信息，我们的数据服务人员将会尽快与您联系，并确定您的具体需求。</p>
                        <p class="pea">由于EPS每天都将接收到大量的数据定制申请，因此，根据数据定制的内容不同我们将会在3-7天反馈数据定制结果。另外，若用户需求的数据量比较大或需要进行加工处理，我们将会收取一定的费用，实际费用将根据具体情况进行确定。</p>
                        <div class="frombos">
                            <form id="login_form" @submit.prevent="getSubmit()">
                                <div class="fromNameLabel">姓名</div>
                                <div class="fromText">
                                    <input type="text" v-model="userObjs.nameUser" name="Data_Name" autocomplete="off">
                                    <span class="mspan">*</span>
                                </div>
                                <div class="fromNameLabel">邮箱</div>
                                <div class="fromText">
                                    <input type="text" v-model="userObjs.emailUser" name="Data_Email" autocomplete="off">
                                    <span class="mspan">*</span>
                                </div>
                                <div class="fromNameLabel">单位</div>
                                <div class="fromText">
                                    <input type="text" v-model="userObjs.companyUser" name="Data_Unit" autocomplete="off">
                                    <span class="mspan">*</span>
                                </div>
                                <div class="fromNameLabel">联系电话</div>
                                <div class="fromText">
                                    <input type="text" v-model="userObjs.phoneUser" name="Data_Phone" autocomplete="off">
                                    <span class="mspan">*</span>
                                </div>
                                <div class="fromNameLabel">数据内容</div>
                                <div class="fromArea">
                                    <textarea v-model="userObjs.contentUser" name="Data_Content"></textarea>
                                    <span class="mspan">*</span>
                                </div>
                                <div class="errorDta">{{errorData}}</div>
                                <div class="fromBtn">
                                    <button type="submit" class="btnSubs">提交</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from './commons/Header.vue';
import Footer from './commons/Footer.vue';
import {
    checkEmail,
    checkMobile,
    ajaxPost
} from '../until/common.js';
export default {
	name:'Customiz',
	components:{
        Header,
        Footer
    },
    data() {
        return {
            errorData:'',
            userObjs:{
                nameUser:'',
                emailUser:'',
                companyUser:'',
                phoneUser:'',
                contentUser:'',
            }
        }
    },
    methods:{
        getSubmit(){
            var namea = /^[\u4e00-\u9fa5]{2,5}$/;
            if(this.userObjs.nameUser==''){
                this.errorData = '提示：请填写姓名！';
                window.setTimeout(()=>{
                    this.errorData = '';
                },2000);
                return;
            }else if(namea.test(this.userObjs.nameUser)==false){
				this.errorData = '提示：请填写有效的姓名！';
                window.setTimeout(()=>{
                    this.errorData = '';
                },2000);
                return;
			}else if(this.userObjs.emailUser==''){
                this.errorData = '提示：请填写的您的邮箱！';
                window.setTimeout(()=>{
                    this.errorData = '';
                },2000);
                return;
            }else if(!checkEmail(this.userObjs.emailUser)){
                this.errorData = '提示：您填写的邮箱格式不正确！';
                window.setTimeout(()=>{
                    this.errorData = '';
                },2000);
                return;
            }else if(this.userObjs.companyUser==''){
                this.errorData = '提示：您填写的单位名称！';
                window.setTimeout(()=>{
                    this.errorData = '';
                },2000);
                return;
            }else if(this.userObjs.phoneUser==''){
                this.errorData = '提示：请填写的您的联系方式！';
                window.setTimeout(()=>{
                    this.errorData = '';
                },2000);
                return;
            }else if(!checkMobile(this.userObjs.phoneUser)){
                this.errorData = '提示：您填写的手机号格式不正确!';
                setTimeout(function(){ 
                    this.errorData = '';
                }, 2000);
                return;
            }else if(this.userObjs.contentUser==''){
                this.errorData = '提示：您填写的数据内容!';
                setTimeout(function(){ 
                    this.errorData = '';
                }, 2000);
                return;
            }else{
                var params = {
                    sp:'DataCustom_ADDNEW',
                    p:'Data_Name;Data_Unit;Data_Email;Data_Phone;Data_Content',
                    v:this.userObjs.nameUser+';'+this.userObjs.companyUser+';'+this.userObjs.emailUser+';'+this.userObjs.phoneUser+';'+this.userObjs.contentUser			
                }
                ajaxPost('getcomgedatas',params).then((res)=> {
                    if(res&&res.data){
                        if(res.data.status=='success'){
                            this.userObjs = {}
                            alert(res.data.message);
                        }else{
                            alert(res.data.message)
                        }
                        // var json = eval('(' + res.data + ')');
                        // alert(json.Table[0].info);
                    }else{
                        return false
                    }
                }).catch(function(error) {
                    window.console.log(error);
                });
            }
            return false;
        }
    },
}
</script>